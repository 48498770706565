<script lang="ts" setup>
import { useUniversalPixel } from "~/composables/universalPixel";

useUniversalPixel().init();
</script>

<template>
    <div>
        <main id="main-content" class="relative mx-auto min-h-screen max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <slot />
        </main>
    </div>
</template>
faul

<style scoped></style>
